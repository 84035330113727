import { paths } from './api';
import { Content, ContentTypeEnum, MethodsEnum, OperationsEnum } from '@models';
import useAxios from '@hooks/useFetch';

const POST_UPLOAD_IMAGE = '/api/ChainDetails/UploadImage';

type PostImagUpload = paths[typeof POST_UPLOAD_IMAGE][MethodsEnum.POST];
type Response = PostImagUpload[OperationsEnum.RESPONSES]['200'][Content][ContentTypeEnum.Json];

export function useImageUpload() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<Response>({
        url: POST_UPLOAD_IMAGE,
        method: MethodsEnum.POST,
        lazy: true,
        initialConfig: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    });

    return { data, isLoading, error, doFetch } as const;
}