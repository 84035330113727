import { useCallback, useEffect } from 'react';
import cn from 'classnames';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { FileUploader } from 'react-drag-drop-files';
import type FileUploaderTypes from 'react-drag-drop-files/dist/src/FileUploader';

import { FCX } from '@core/models';
import { IconTrash } from '@svg';
import { resizeImageFile } from '@core/utils/file';
import { useNotifications } from '@toolpad/core/useNotifications';
import { CircularProgress } from '@mui/material';

const IMAGE_TYPES = ['JPG', 'PNG', 'JPEG', 'GIF', 'SVG', 'WEBP', 'BMP', 'TIFF', 'ICO', 'CUR', 'HEIC', 'HEIF'];
const FileUploaderTyped: typeof FileUploaderTypes = FileUploader;

const ImageInput: FCX<{
    onImageSelectedCallback?: (_imgBlob: Blob | null) => void;
    imageUrl?: string;
    isLoading?: boolean;
}> = ({
    onImageSelectedCallback,
    imageUrl,
    isLoading,
}) => {
    const notifications = useNotifications();

    const setFile = useCallback(
        async (file: File, notify?: boolean) => {
            try {
                const imgBlob = await resizeImageFile(file);
                onImageSelectedCallback?.(imgBlob);
                if (notify) {
                    notifications.show('Image added successfully', {
                        severity: 'success',
                        autoHideDuration: 4500,
                    });
                }
            } catch (error) {
                console.log(error);
                if (notify) {
                    notifications.show('Image adding failed', {
                        severity: 'error',
                        autoHideDuration: 4500,
                    });
                }
            }
        },
        [notifications, onImageSelectedCallback],
    );

    const handleFileChange = async (event: File) => {
        if (event instanceof File) {
            await setFile(event);
        }
    };

    useEffect(() => {
        function handleWindowPaste(event: ClipboardEvent) {
            const items = event.clipboardData?.items;
            let file: File | null = null;

            if (!items) {
                return;
            }

            for (let i = 0; i < items.length; i++) {
                if (items[i].kind === 'file' && items[i].type.includes('image')) {
                    file = items[i].getAsFile();
                    break;
                }
            }

            if (file) {
                setFile(file, true);
            }
        }

        window.addEventListener('paste', handleWindowPaste as EventListener);

        return () => {
            window.removeEventListener('paste', handleWindowPaste as EventListener);
        };
    }, [setFile]);

    return (
        <div className="ImageInput">
            <FileUploaderTyped
                handleChange={(e) => {
                    if (Array.isArray(e)) {
                        return handleFileChange(e[0]);
                    }
                    return handleFileChange(e);
                }}
                types={IMAGE_TYPES}
                fileOrFiles={imageUrl ? undefined : null}
                maxSize={100}
            >
                <div
                    className={cn('ImageInput__preview', {
                        active: !!imageUrl,
                    })}
                >
                    {isLoading && (
                        <CircularProgress size={60} />
                    )}
                    {!isLoading && imageUrl && <img src={imageUrl}  alt="thumbnail"/>}
                </div>
            </FileUploaderTyped>
            <div className="ImageInput__actions">
                <Button className="ImageInput__button" component="label" role={undefined} tabIndex={-1}>
                    <span>Upload image</span>
                    <input
                        className="ImageInput__hidden"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            if (e.target.files) {
                                handleFileChange(e.target.files[0]);
                            }
                        }}
                    />
                </Button>
                <IconButton
                    onClick={() => {
                        onImageSelectedCallback?.(null);
                    }}
                    color="error"
                >
                    <IconTrash />
                </IconButton>
            </div>
        </div>
    );
};

export default ImageInput;
