import { CircularProgress } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Header } from '@vms/Header';
import { IconPlus } from '@svg';
import { Table } from '@components/Table';
import { Route } from '@core/routes/Route';
import { TextInput } from '@components/Inputs';
import Button, { ButtonType } from '@components/Button';
import { copyToClipboard, formatDefaultDate, replaceRouteParam } from '@core/utils/string';
import useCommonEnums, { SurveyStatusIdEnum } from '@core/hooks/useCommonEnums';

import useSurveyDetails from './useSurveyDetails';
import CustomFielding from './components/CustomFielding';
import DraftSurveyDetails from './components/DraftSurveyDetails';
import SurveyDetailsTableConfig from './SurveyDetailsTableConfig';
import ClosedSurveyDetails from './components/ClosedSurveyDetails';
import { useGetSurveyInitialData } from '@core/api/useSurveys';
import { useNotifications } from '@toolpad/core/useNotifications';

const SurveyDetails = () => {
    const { findCommonNameById } = useCommonEnums();
    const notifications = useNotifications();

    const {
        data: initialData,
        isLoading: isInitialDataLoading,
    } = useGetSurveyInitialData();

    const {
        data: {
            surveyDetails,
            surveyItems,
            isLoading: isDataLoading,
        },
        form,
        onDeleteItem,
        onSaveSurvey,
    } = useSurveyDetails();

    const isLoading = isDataLoading || isInitialDataLoading;

    if (isLoading) {
        return (
            <div className="SurveyDetails__loader">
                <CircularProgress size={60}/>
            </div>
        );
    }

    return (
        <div className="SurveyDetails">
            <div className="SurveyDetails__header">
                <div className="SurveyDetails__header-col">
                    <div className="SurveyDetails__name">Survey Name: {surveyDetails?.customName}</div>
                </div>
                <div className="SurveyDetails__header-col">
                    {surveyDetails?.links?.map((link) => (
                        <Button
                            key={link.providerName}
                            modifiers={['h-tall', 'no-transform']}
                            onClick={() => {
                                copyToClipboard(link.url ?? '');
                                notifications.show(`${link.providerName} Survey Link copied!`, {
                                    severity: 'success',
                                    autoHideDuration: 4500,
                                });
                            }}
                        >
                            <ContentCopyIcon/> {link.providerName} Link
                        </Button>
                    ))}

                    <TextInput
                        value={form.state.cmixId ?? ''}
                        setValue={form.actions.setField.bind(null, 'cmixId')}
                        placeholder="CMIX Survey ID"
                        isDisabled={surveyDetails?.status !== SurveyStatusIdEnum.Draft}
                        style={{ textAlign: 'center' }}
                    />
                </div>
            </div>
            <div className="SurveyDetails__secondary-title">Fielding Parameters</div>
            <div className="SurveyDetails__fielding-container">
                <div className="SurveyDetails__param-column">
                    <div className="SurveyDetails__section-title">Summary</div>
                    <div className="SurveyDetails__param-title">Survey Status:</div>
                    <div className="SurveyDetails__param-title">Fielding start date:</div>
                    <div className="SurveyDetails__param-title">Fielding end date:</div>
                    <div className="SurveyDetails__param-title" style={{ marginTop: '2px' }}>
                        custom fielding:
                    </div>
                </div>
                <div className="SurveyDetails__param-value-column">
                    <div className="SurveyDetails__param-value">
                        {findCommonNameById('surveyStatuses', surveyDetails?.status)}
                    </div>
                    <div className="SurveyDetails__param-value">
                        {surveyDetails?.startDate ? formatDefaultDate(surveyDetails?.startDate) : '- - -'}
                    </div>
                    <div className="SurveyDetails__param-value">
                        {surveyDetails?.endDate ? formatDefaultDate(surveyDetails?.endDate) : '- - -'}
                    </div>
                    <CustomFielding
                        form={form}
                        surveyStatusId={surveyDetails?.status}
                        customFieldingOptions={initialData?.customFielding ?? []}
                    />
                </div>
                <div className="SurveyDetails__spacer"></div>
                <div className="SurveyDetails__param-column">
                    <div className="SurveyDetails__section-title">Item count</div>
                    <div className="SurveyDetails__param-title">express items:</div>
                    <div className="SurveyDetails__param-title">full concepts:</div>
                    <div className="SurveyDetails__param-title">public items:</div>
                    <div className="SurveyDetails__param-title">other items:</div>
                    <div className="SurveyDetails__param-title uppercase">total items in this survey:</div>
                </div>
                <div className="SurveyDetails__param-value-column">
                    <div className="SurveyDetails__param-value right-align">
                        {surveyDetails?.itemsCount?.express ?? 0}
                    </div>
                    <div className="SurveyDetails__param-value right-align">
                        {surveyDetails?.itemsCount?.fullConcept ?? 0}
                    </div>
                    <div className="SurveyDetails__param-value right-align">
                        {surveyDetails?.itemsCount?.public ?? 0}
                    </div>
                    <div className="SurveyDetails__param-value right-align">
                        {surveyDetails?.itemsCount?.other ?? 0}
                    </div>
                    <div className="SurveyDetails__param-value right-align">
                        {surveyDetails?.itemsCount?.total ?? 0}
                    </div>
                </div>

                {surveyDetails?.status === SurveyStatusIdEnum.Closed && (
                    <ClosedSurveyDetails keyMetricAverages={surveyDetails?.keyMetricAverages}/>
                )}

                {surveyDetails?.status === SurveyStatusIdEnum.Draft && (
                    <DraftSurveyDetails totalSurveySample={surveyDetails?.totalSurveySample} form={form}/>
                )}
            </div>
            <div className="Separator"/>
            <Header
                rightContent={
                    <div className="ChainItems__actions">
                        {surveyDetails?.status === SurveyStatusIdEnum.Draft && (
                            <Button
                                type={ButtonType.Link}
                                modifiers={['h-tall']}
                                link={replaceRouteParam(Route.SurveyAddItems, ':id', surveyDetails?.id ?? '')}
                                state={{ survey: surveyDetails }}
                            >
                                <IconPlus/>
                                Add New Item
                            </Button>
                        )}
                        <TextInput
                            value={surveyItems.searchValue}
                            setValue={surveyItems.setSearchValue}
                            placeholder="Search"
                        />
                    </div>
                }
            >
                <span className="SurveyDetails__secondary-title">included items</span>
            </Header>
            <div className="SurveyDetails__table-container">
                <SurveyDetailsTableConfig
                    data={surveyItems.searchResults ?? []}
                    surveyStatus={surveyDetails?.status}
                    onDeleteItem={onDeleteItem}
                >
                    <Table rowsCount={surveyItems.searchResults?.length ?? 0} isLoading={false}/>
                </SurveyDetailsTableConfig>
            </div>

            <div className="SurveyDetails__footer">
                <Button modifiers={['h-tall']} onClick={onSaveSurvey}>
                    Save & Exit
                </Button>
            </div>
        </div>
    );
};

export default SurveyDetails;
