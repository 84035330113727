import dayjs from 'dayjs';

import { Route } from '@core/routes/Route';

export function replaceRouteParam(route: Route, routeParamKey: string, param: string | number): string {
    return route.replace(routeParamKey, String(param));
}

export function currencyFormatter(value: string) {
    if (!value) return '';

    const number = Number(value);
    const amount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number / 100);

    return `${amount}`;
}

export function formatDefaultDate(date?: string | null) {
    if (!date) return '';

    return dayjs(date).format('MM/DD/YY');
}

export function formatNumber(num?: number | null) {
    if (num === null || num === undefined) {
        return '';
    }

    return new Intl.NumberFormat('en-US').format(num);
}

export function formatBoolean(value?: boolean | null) {
    return value ? 'Y' : 'N';
}

export function copyToClipboard(text: string) {
    const textarea = document.createElement('TEXTAREA') as HTMLTextAreaElement;
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
}