import useAxios from '@core/hooks/useFetch';
import { Content, ContentTypeEnum, MethodsEnum, OperationsEnum } from '@core/models';

import { paths, components } from './api';

const GET_SURVEYS = '/api/Survey/GetAllExtended';
const GET_SURVEY_DETAILS = '/api/Survey/GetSurveyDetails/';
const GET_SURVEY_INITIAL_DATA = '/api/Survey/GetInitialData';
const GET_SURVEY_DEFAULT_ITEMS_RATIOS_DATA = '/api/Survey/GetDefaultItemsRatios';
const GET_LAUNCH_SURVEY = '/api/Survey/LaunchSurvey/';
const POST_CLOSE_SURVEY = '/api/Survey/CloseSurvey';
const POST_SAVE_SURVEY = '/api/Survey/SaveSurvey';

type GetSurveys = paths[typeof GET_SURVEYS][MethodsEnum.GET];
export type GetSurveysResponse = GetSurveys[OperationsEnum.RESPONSES]['200'][Content][ContentTypeEnum.Json];
export type ExtendedSurvey = components['schemas']['SurveysExtendedListDto'];

export function useGetSurveys() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<GetSurveysResponse>({
        url: GET_SURVEYS,
        method: MethodsEnum.GET,
    });

    return { data, isLoading, error, doFetch } as const;
}

type GetSurveyDetails = paths[`/api/Survey/GetSurveyDetails/${number}`][MethodsEnum.GET];
export type GetSurveyDetailsResponse = GetSurveyDetails[OperationsEnum.RESPONSES]['200'][Content][ContentTypeEnum.Json];
export type SurveyDetailsItem = components['schemas']['SurveyDetailsItem'];

export function useGetSurveyDetails(surveyId?: string, lazy = false) {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<GetSurveyDetailsResponse>({
        url: `${GET_SURVEY_DETAILS}${surveyId}`,
        method: MethodsEnum.GET,
        lazy,
    });

    return { data, isLoading, error, doFetch } as const;
}

type GetSurveyInitialData = paths[typeof GET_SURVEY_INITIAL_DATA][MethodsEnum.GET];
export type GetSurveyInitialDataResponse =
    GetSurveyInitialData[OperationsEnum.RESPONSES]['200'][Content][ContentTypeEnum.Json];

export function useGetSurveyInitialData() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<GetSurveyInitialDataResponse>({
        url: GET_SURVEY_INITIAL_DATA,
        method: MethodsEnum.GET,
    });

    return { data, isLoading, error, doFetch } as const;
}

type GetDefaultItemsRatiosData = paths[typeof GET_SURVEY_DEFAULT_ITEMS_RATIOS_DATA][MethodsEnum.GET];
export type GetDefaultItemsRatiosDataResponse = GetDefaultItemsRatiosData[OperationsEnum.RESPONSES]['200'][Content][ContentTypeEnum.Json];
export type GetDefaultItemsRatiosDataParams = GetDefaultItemsRatiosData[OperationsEnum.PARAMETERS]['query'];

export function useGetSurveyDefaultRatiosData(params: GetDefaultItemsRatiosDataParams) {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<GetDefaultItemsRatiosDataResponse, GetDefaultItemsRatiosDataParams>({
        url: GET_SURVEY_DEFAULT_ITEMS_RATIOS_DATA,
        method: MethodsEnum.GET,
        initialConfig: { params: { ...params, testField: 1 }, },
    });

    return { data, isLoading, error, doFetch } as const;
}

type CloseSurvey = paths[typeof POST_CLOSE_SURVEY][MethodsEnum.POST];
export type CloseSurveyParams = NonNullable<CloseSurvey[OperationsEnum.PARAMETERS]['query']>;

export function useCloseSurvey() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<string, CloseSurveyParams>({
        url: POST_CLOSE_SURVEY,
        method: MethodsEnum.POST,
        lazy: true,
    });

    return { data, isLoading, error, doFetch } as const;
}

export function useLaunchSurvey(surveyId: string) {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<string, CloseSurveyParams>({
        url: `${GET_LAUNCH_SURVEY}${surveyId}`,
        lazy: true,
    });

    return { data, isLoading, error, doFetch } as const;
}

type SaveSurvey = paths[typeof POST_SAVE_SURVEY][MethodsEnum.POST];
export type BodySaveSurvey = NonNullable<SaveSurvey[OperationsEnum.REQUEST]>[Content][ContentTypeEnum.Json];

export function useSaveSurvey() {
    const {
        data,
        isLoading,
        error,
        doFetch
    } = useAxios<string, BodySaveSurvey>({
        url: POST_SAVE_SURVEY,
        method: MethodsEnum.POST,
        lazy: true,
    });

    return { data, isLoading, error, doFetch } as const;
}
