import dayjs, { Dayjs } from 'dayjs';
import { useParams } from 'react-router-dom';
import { NumberFormatBase } from 'react-number-format';
import { FormEvent, useCallback, useEffect } from 'react';
import { useNotifications } from '@toolpad/core/useNotifications';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { FCX } from '@core/models';
import useForm from '@core/hooks/useForm';
import { Select } from '@components/Inputs/Select';
import { currencyFormatter } from '@core/utils/string';
import useCommonEnums from '@core/hooks/useCommonEnums';
import { ImageInput } from '@components/Inputs/ImageInput';
import { ChainDetailsMenuItem, GetChainDetailsResponse, useUpsertMenuItem } from '@core/api/useChainDetails';

import './ChainItemPopup.scss';
import { useImageUpload } from '@core/api/useImageUpload';

type FormState = {
    image: string | null;
    displayDate: Dayjs | null;
    entryDate: Dayjs | null;
    itemName: string;
    itemDescription: string;
    note: string;
    price: string;
    status: string;
    itemType: string;
    uiStatus: string;
};

const customStatuses = [
    { id: '1', title: 'Live' },
    { id: '2', title: 'Draft' },
];

const DEFAULT_STATE: FormState = {
    image: null,
    entryDate: dayjs(new Date()),
    displayDate: dayjs(new Date()).add(1, 'month').date(1),
    itemName: '',
    itemDescription: '',
    note: '',
    price: '',
    status: '3', // Default to 'Limited Time Offer'
    itemType: '',
    uiStatus: customStatuses[0].id,
};

const ChainItemPopup: FCX<{
    open: boolean;
    handleClose: (_shouldRefreshItems?: boolean) => void;
    initialData?: ChainDetailsMenuItem;
    chainDetailsData: GetChainDetailsResponse | null;
}> = ({
    open,
    handleClose,
    initialData,
    chainDetailsData
}) => {
    const { id: chainId } = useParams();
    const { common } = useCommonEnums();
    const { doFetch } = useUpsertMenuItem();
    const notifications = useNotifications();
    const {
        doFetch: doFetchImageUpload,
        isLoading: isImageLoading,
    } = useImageUpload();

    const {
        state: formState,
        actions
    } = useForm(DEFAULT_STATE);

    const mapCommonToSelectOptions = useCallback(
        (commonType: string) =>
            common?.[commonType as keyof typeof common]?.map((item) => ({
                title: item?.name ?? '',
                id: item?.id?.toString() ?? '',
            })) ?? [],
        [common],
    );

    const onClosePopup = useCallback(
        (refresh = false) => {
            actions.resetForm();

            handleClose(refresh);
        },
        [actions, handleClose],
    );

    useEffect(() => {
        if (initialData) {
            actions.setFields({
                image: initialData?.smallImageUrl ?? '',
                displayDate: dayjs(initialData.displayDate),
                entryDate: dayjs(initialData.entryDate),
                itemName: initialData?.name ?? '',
                itemDescription: initialData?.description ?? '',
                note: initialData?.note ?? '',
                price: initialData?.price ?? '',
                status: initialData?.kindId?.toString() ?? '',
                itemType: initialData?.categoryId?.toString() ?? '',
                uiStatus: initialData?.statusId?.toString() ?? '',
            });
        } else {
            actions.resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData]);

    const handleImageChange = (imgBlob: Blob | null) => {
        if (imgBlob === null) {
            actions.setField<FormState['image']>('image', null);
            return;
        }

        doFetchImageUpload({
            data: {
                image: imgBlob,
            },
        }).then((data) => {
            if (data) {
                const { data: imgSrc } = data;
                actions.setField<FormState['image']>('image', imgSrc as unknown as string);
            }
        })
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClosePopup()}
            className="ChainItemPopup"
            classes={{
                paper: 'ChainItemPopup__container',
            }}
            PaperProps={{
                component: 'form',
                onSubmit: (event: FormEvent<HTMLFormElement>) => {
                    event.preventDefault();

                    doFetch({
                        data: {
                            SipChainCode: chainId,
                            FFChainId: chainDetailsData?.ffChainId ?? 0,
                            ImageName: formState?.image ? (formState.image as unknown as string) : '',
                            Id: initialData?.id ?? 0,
                            EntryDate: formState.entryDate?.format('YYYY-MM-DD'),
                            DisplayDate: formState.displayDate?.date(1).format('YYYY-MM-DD'),
                            Name: formState.itemName,
                            Description: formState.itemDescription,
                            Note: formState.note,
                            Price: formState.price,
                            KindId: parseInt(formState.status),
                            CategoryId: parseInt(formState.itemType),
                            StatusId: parseInt(formState.uiStatus),
                        },
                    }).then((res) => {
                        if (res?.status === 200 && res?.data?.errors?.length === 0) {
                            notifications.show('Item saved successfully', {
                                severity: 'success',
                                autoHideDuration: 4500,
                            });
                        } else {
                            notifications.show('Item save failed', {
                                severity: 'error',
                                autoHideDuration: 4500,
                            });
                        }

                        actions.resetForm();
                        onClosePopup(true);
                    });
                },
            }}
        >
            <DialogTitle>{initialData ? 'Edit Item' : 'Add Item'}</DialogTitle>
            <DialogContent>
                <div className="ChainItemPopup__form-body">
                    <div className="ChainItemPopup__form-row">
                        <ImageInput
                            onImageSelectedCallback={handleImageChange}
                            imageUrl={formState.image || ''}
                            isLoading={isImageLoading}
                        />
                        <div className="ChainItemPopup__date-container">
                            <DatePicker
                                label="Display Date"
                                views={['month', 'year']}
                                format="MMM YY"
                                value={formState.displayDate}
                                onChange={(date) => {
                                    actions.setField<FormState['displayDate']>('displayDate', date);
                                }}
                            />
                            <DatePicker
                                label="Entry Date"
                                value={formState.entryDate}
                                onChange={(date) => {
                                    actions.setField('entryDate', date);

                                    if (date) {
                                        actions.setField('displayDate', date.add(1, 'month'));
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="ChainItemPopup__form-row">
                        <TextField
                            autoFocus
                            margin="dense"
                            id="itemName"
                            name="itemName"
                            label="Item Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={formState.itemName}
                            onChange={(event) => actions.setField('itemName', event.target.value)}
                        />
                    </div>
                    <div className="ChainItemPopup__form-row">
                        <TextField
                            id="itemDescription"
                            name="itemDescription"
                            label="Item Description"
                            fullWidth
                            multiline
                            variant="standard"
                            value={formState.itemDescription}
                            onChange={(event) => actions.setField('itemDescription', event.target.value)}
                        />
                    </div>
                    <div className="ChainItemPopup__form-row">
                        <TextField
                            id="note"
                            name="note"
                            label="Note"
                            fullWidth
                            multiline
                            maxRows={2}
                            minRows={1}
                            variant="standard"
                            value={formState.note}
                            onChange={(event) => actions.setField('note', event.target.value)}
                        />
                    </div>
                    <div className="ChainItemPopup__form-row">
                        <NumberFormatBase
                            customInput={TextField}
                            format={currencyFormatter}
                            prefix={'$'}
                            {...{
                                id: 'price',
                                name: 'price',
                                type: 'text',
                                label: 'Price',
                                fullWidth: true,
                                variant: 'standard',
                            }}
                            value={formState.price}
                            onValueChange={({ formattedValue }) => actions.setField('price', formattedValue)}
                        />

                        <Select
                            label="Status"
                            id="status"
                            value={formState.status}
                            setValue={(value) => {
                                actions.setField('status', value);
                            }}
                            options={mapCommonToSelectOptions('itemKinds')}
                        />
                    </div>
                    <div className="ChainItemPopup__form-row">
                        <Select
                            label="Item Type"
                            id="itemType"
                            value={formState.itemType}
                            setValue={(value) => {
                                actions.setField('itemType', value);
                            }}
                            options={mapCommonToSelectOptions('itemCategories')}
                        />
                        <Select
                            label="UI Status"
                            id="uiStatus"
                            value={formState.uiStatus}
                            setValue={(value) => {
                                actions.setField('uiStatus', value);
                            }}
                            options={customStatuses}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={() => onClosePopup()}>
                    Cancel
                </Button>
                <Button type="submit">Save & Exit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChainItemPopup;
